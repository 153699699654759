import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Hero = () => (
    <div className="hero-wrapper row justify-content-center align-items-center">
      <div className="col-12 col-md-6 col-lg-4">
        <h1>So <span className="alt-color">Fresh</span>, So <span className="alt-color">Clean</span></h1>
        <p>Come get a fresh wax from our waxing experts in Ocean City, MD.</p>
        <p className="text-center">
        <a href="https://app.salonrunner.com/customer/login.htm?id=16912">BOOK APPOINTMENT</a>
        </p>
      </div>
      <div className="col-10 col-md-6 col-lg-6 ">
      <StaticImage      
        className="hero-img-wrap"
        imgClassName="hero-img p-3 img-fluid"
        loading="eager" 
        alt="waxed ladies relaxing on the beach"
        src={
          "../images/splash-2.png"
        }
        objectFit="contain"        
        objectPosition="50% 50%"
        
      />
        
      </div>
      
      
    </div>
  )
  
  export default Hero