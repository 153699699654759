import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"


const IndexPage = () => (
  <Layout>
    <Hero />
    <Seo title="OC Beach Wax in Ocean City, MD" description="OC Beach Wax is entirely dedicated to your waxing needs. Unlike a salon or spa, our focus is exclusively on a waxing experience that is clean, comfortable and fun." 
    jsonld='{"@context": "https://schema.org","@type": "Organization","url": "https://ocbeachwax.com","name": "OC Beach Wax","address": {"@type": "PostalAddress","addressLocality": "Ocean City","addressRegion": "MD","postalCode": "21842","streetAddress": "103 120th Street Unit J"}}' />
    <div className="row justify-content-center home-options-wrapper">
      <div className="col-11 col-md-5 home-options home-ladies">
        <h2>Ladies</h2>
        <StaticImage      
          className="options-img-wrap"
          imgClassName="options-img img-fluid"
          loading="lazy" 
          alt="waxed woman relaxing on the beach"
          src={
            "../images/options-ladies-2a.jpg"
          }
          objectFit="contain"        
          objectPosition="50% 50%"
          
        />          
        <div className="card-wrapper">
          <div className="service-card">
              <h3 className="text-center mt-3">Waxing &amp; Facials</h3>
              <p>Ladies need to take care of themselves regularly. We offer waxing and other services for ladies including arms, back, eyebrows, face, legs, and of course bikini waxing and brazilians. Exfoliate, cleanse, and restore vitality with our anti-aging or seasonal facials. </p>                    
          </div>   
          <div className="action-wrapper">
            <a className="book" href="https://app.salonrunner.com/customer/login.htm?id=16912">BOOK</a>     
            <Link className="pricing" to="/ladies">Info &amp; Pricing</Link>              
          </div>             
        </div>
        
      </div>
      <div className="col-11 col-md-5  home-options home-men">
        <h2>Men</h2>
        <StaticImage      
          className="options-img-wrap"
          imgClassName="options-img img-fluid"
          loading="lazy" 
          alt="waxed man surfing"
          src={
            "../images/options-men-2a.jpg"
          }
          objectFit="contain"        
          objectPosition="50% 50%"
          
        />
        
          <div className="card-wrapper">
              <div className="service-card">
                  <h3 className="text-center mt-3">Waxing &amp; Facials</h3>
                  <p>Real men aren't afraid to take care of themselves and feel confident. We offer waxing for men including arms, back, eyebrows, face, legs, and chest. Exfoliate, cleanse, and restore vitality with our anti-aging or seasonal facials. </p>                    
              </div>
          </div>
          <div className="action-wrapper">
            <a className="book" href="https://app.salonrunner.com/customer/login.htm?id=16912">BOOK</a>                   
            <Link className="pricing" to="/men">Info &amp; Pricing</Link>   
          </div>
      </div>
      
    </div> 
    <div className="row">
      <div className="col-12 g-0">
        <div className="shape">&nbsp;</div>
      </div>
    </div>
    <div className="row justify-content-center intro">
      <div className="col-12 text-center">
        <h2 className="mt-3 mb-3">Locations</h2>
      </div>
      <div className="col-12 col-md-6 col-lg-4 text-center">        
        <h3>120th Street</h3>
        <p>103 120th Street Unit J<br />Ocean City, MD 21842<br />(Food Lion Shopping Center)<br /><a href="tel:4105241686">410-524-1686</a></p>   
      </div>
      <div className="col-12 col-md-6 col-lg-4 text-center">
        <h3>Ocean Pines</h3>
        <p>11022 Nicholas Lane #2<br />Ocean Pines, MD 21811<br />(Business Center)<br /><a href="tel:4438801990">443-880-1990</a></p>             
      </div>
      <div className="col-12 col-md-10 col-lg-8">
      <p>We are wax experts dedicated entirely to <strong>proper body waxing</strong>. We take every precaution to maintain the highest levels of hygiene. We wouldn't dream of double dipping and gloves are always worn. Because most of what we do is waxing, our atmosphere is slightly different. We offer a <strong>modern, fun, clean, spa-like alternative</strong> to hair and nail salons as well as a less painful approach to waxing.
      SIT BACK...RELAX...AND WAX!</p>
      </div>      
    </div>        
    <div className="row justify-content-center appts-fb mb-3">
      <div className="col-12 col-lg-5 col-lg-4">
        <h3 className="text-center">Appointments</h3>
        <p><b>Please be advised that if you are more than 5-10 minutes late for your appointment you may have to reschedule.</b> Service lengths vary and are set according to time needed to complete. Summer traffic can play a big part in this so please be mindful when scheduling your next appointment.</p>
        <p className="text-center"><a href="/faq" className="btn btn-outline-light text-center">More FAQ</a></p>
      </div>
      <div className="col-12 col-md-5 col-lg-4">
        <div id="fbWrapper" className="" itemScope itemType="http://schema.org/LocalBusiness">	
            <div className="fb-page" data-href="https://www.facebook.com/OcBeachWax" data-tabs="timeline" data-width="300" data-height="400" data-small-header="true" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="false"><blockquote cite="https://www.facebook.com/OcBeachWax" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/OcBeachWax" className="text-light">OC Beach Wax</a></blockquote></div>
        </div>
      </div>      
    </div>
  </Layout>
)

export default IndexPage
